import React, { useEffect, useState, useRef } from "react"
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Box } from "@mui/system";
import { IconButton } from "@mui/material";
import { motion, useAnimation, useInView } from "framer-motion"

import { getMaskStaticPathWithAspectRatio } from "../../utils/staticPathsForMaskImages"
import { getBgStaticPathWithAspectRatio } from "../../utils/staticPathsForBgImages"


const sxStyleWrap = {
    width: "inherit",
    "& img": {
        width: '100%',
        opacity: 0.3,
    }
}

const gradientAft = "linear-gradient(transparent, beige)" // `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 30px, rgba(0,0,0,1) 30px, rgba(0,0,0,1) 30px)`;
const gradientBef = "linear-gradient(maroon, beige)" // `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 30px, rgba(0,0,0,1) 30px, rgba(0,0,0,1) 30px)`;
const gradient = "radial-gradient(orange, yellow)" // `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 0px, rgba(0,0,0,1) 0px, rgba(0,0,0,1) 30px)`;

const variants = {
    befVisible: {
        "--bef-x": "translateX(0%)",
        "--bef-maskSize": "40%", //" "10% 30%",
        "--bef-maskPosition": [ "0% 0%", "100% 0%", "100% 100%", "0% 100%", "0% 0%", ],
        "--aft-x": "translateX(0%)",
        "--aft-maskSize": "40%", //" "10% 30%",
        "--aft-maskPosition": [ "0% 0%", "100% 0%", "100% 100%", "0% 100%", "0% 0%", ],// "right",
    },
    befHidden: (baseMaskSizePerc) => ({
        x: "-100%",
        opacity: 0,
        maskSize: `${baseMaskSizePerc}%`,
        WebkitMaskSize: `${baseMaskSizePerc}%`, // "10% 100%",
        "--mid-maskPosition": "0% 0%",

        "--bef-x": "translateX(100%)",
        "--bef-maskSize": "50%", // "30% 100%",
        "--bef-maskPosition": "0% 0%",

        "--aft-x": "translateX(100%)",
        "--aft-maskSize": "20%", // "30% 100%",
        "--aft-maskPosition": "0% 0%",
    })
};

const MaskingChildren = ({
    children, maskImg = "mask13", maskImgBef = "mask14", maskImgAft = "mask14",
    isBgImgGradient = false, bgImg = "bgI5", isBgImgBefGradient = false, bgImgBef = "bgI3", isBgImgAftGradient = false, bgImgAft = "bgI6",
    baseMaskSizePerc = 50, maskShiftPerc = 20
}) => {

    const [ replay, setReplay ] = useState(true);
    const [ loadedMask, setLoadedMask ] = useState(false)
    const [ loadedMaskBef, setLoadedMaskBef ] = useState(false)
    const [ loadedMaskAft, setLoadedMaskAft ] = useState(false)
    const [ loadedBg, setLoadedBg ] = useState(false)
    const [ loadedBgBef, setLoadedBgBef ] = useState(false)
    const [ loadedBgAft, setLoadedBgAft ] = useState(false)

    const ctrlWrap = useAnimation()
    const ctrlImgBase = useAnimation()

    const refWrap = useRef(null)
    const isInView = useInView(refWrap, {
        once: false,
        margin: "50px 0px -100px 0px", //  top/right/bottom/left // "0px 0px", //'100px 100px'
    })

    const { path: srcMaskImg, aspectRatio: aspectRatioM } = getMaskStaticPathWithAspectRatio(maskImg)
    const { path: srcMaskImgBef, aspectRatio: aspectRatioMBef } = getMaskStaticPathWithAspectRatio(maskImgBef)
    const { path: srcMaskImgAft, aspectRatio: aspectRatioMAft } = getMaskStaticPathWithAspectRatio(maskImgAft)

    const { path: staticPathBgImg, aspectRatio: aspectRatioBgImg } = isBgImgGradient ? {} : getBgStaticPathWithAspectRatio(bgImg)
    const { path: staticPathBgImgBef, aspectRatio: aspectRatioBgImgBef } = isBgImgBefGradient ? {} : getBgStaticPathWithAspectRatio(bgImgBef)
    const { path: staticPathBgImgAft, aspectRatio: aspectRatioBgImgAft } = isBgImgBefGradient ? {} : getBgStaticPathWithAspectRatio(bgImgAft)

    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        }
    }, [])

    useEffect(() => {
        const imgMask1 = new Image();
        imgMask1.src = srcMaskImg;
        imgMask1.onload = () => {
            setLoadedMask(true)
        }
        const imgMaskBef = new Image();
        imgMaskBef.src = srcMaskImgBef;
        imgMaskBef.onload = () => {
            setLoadedMaskBef(true)
        }
        const imgMaskAft = new Image();
        imgMaskAft.src = srcMaskImgAft;
        imgMaskAft.onload = () => {
            setLoadedMaskAft(true)
        }

        if (!isBgImgGradient) {
            const imgBg = new Image();
            imgBg.src = staticPathBgImg;
            imgBg.onload = () => {
                setLoadedBg(true)
            }
        }

        if (!isBgImgBefGradient) {
            const imgBgBef = new Image();
            imgBgBef.src = staticPathBgImgBef;
            imgBgBef.onload = () => {
                setLoadedBgBef(true)
            }
        }

        if (!isBgImgAftGradient) {
            const imgBgAft = new Image();
            imgBgAft.src = staticPathBgImgAft;
            imgBgAft.onload = () => {
                setLoadedBgAft(true)
            }
        }
        //2-nd alternative: imgMask.addEventListener('load', () => setLoaded(true));
    }, [ isBgImgBefGradient, isBgImgGradient, isBgImgAftGradient, srcMaskImg, srcMaskImgBef, srcMaskImgAft, staticPathBgImg, staticPathBgImgBef, staticPathBgImgAft ])

    useEffect(() => {
        const seq = async () => {
            if (!isMounted.current || !isInView) return
            if (!loadedMask || !loadedMaskBef || !loadedMaskAft || !loadedBg || !loadedBgBef || !loadedBgAft) return
            ctrlWrap.stop()

            ctrlImgBase.start({
                opacity: 0,
                transition: {
                    duration: 1,
                }
            })
            isMounted.current && await ctrlWrap.start({
                x: "0%",
                opacity: 1,
                transition: {
                    duration: 1,
                }
            })

            isMounted.current && await ctrlWrap.start({
                maskSize: "50%", // "30% 100%",
                WebkitMaskSize: "50%", // "30% 100%",
                transition: {
                    duration: 1,
                }
            })
            isMounted.current && await ctrlWrap.start({
                "--mid-maskPosition": [ "0% 0%", "100% 0%", "100% 100%", "0% 100%", "0% 0%", ],
                transition: {
                    duration: 2,
                    delay: 0.3
                }
            })

            isMounted.current && await ctrlWrap.start({
                "--bef-x": "translateX(0%)",
                "--bef-maskSize": `${baseMaskSizePerc - 1 * maskShiftPerc}%`, // "30% 100%",
            }, {
                duration: 1, delay: 0.3
            })
            isMounted.current && await ctrlWrap.start({
                "--bef-maskPosition": [ `${0 + maskShiftPerc}% ${0 + maskShiftPerc}%`, "100% 0%", "100% 100%", "0% 100%", `${0 + maskShiftPerc}% ${0 + maskShiftPerc}%`, ],
            }, {

                duration: 1, delay: 0.3
            })


            isMounted.current && await ctrlWrap.start({
                "--aft-x": "translateX(0%)",
                "--aft-maskSize": `${baseMaskSizePerc - 2 * maskShiftPerc}%`, // "30% 100%",
            }, {
                duration: 1, delay: 0.3
            })
            isMounted.current && await ctrlWrap.start({
                "--aft-maskPosition": [ `${0 + maskShiftPerc}% ${0 + maskShiftPerc}%`, "100% 0%", "100% 100%", "0% 100%", `${0 + maskShiftPerc}% ${0 + maskShiftPerc}%`, ],
            }, {
                duration: 1, delay: 0.3
            })
            isMounted.current && await ctrlImgBase.start({
                opacity: 1,
                transition: {
                    duration: 4,
                },
            })
            isMounted.current && ctrlWrap.start({
                maskSize: "1%", // "30% 100%",
                WebkitMaskSize: "1%", // "30% 100%",
                transition: {
                    duration: 1,
                }
            })
        }
        isMounted.current && seq()
    }, [ loadedMask, loadedMaskBef, loadedBg, loadedBgBef, ctrlWrap, replay, baseMaskSizePerc, ctrlImgBase, loadedBgAft, loadedMaskAft, maskShiftPerc, isInView ])

    return (
        <Box
            ref={refWrap}
            sx={{
                position: 'relative',
                width: "100%",
                maxWidth: "inherit",
                boxShadow: '0px 6px 7px -4px rgb(0 0 0 / 20%), 0px 11px 15px 1px rgb(0 0 0 / 14%), 0px 4px 20px 3px rgb(0 0 0 / 12%)',
                backgroundSize: 'cover',
                "& img": {
                    width: '100%',
                    zIndex: '-10',
                },
            }}>
            <motion.div
                animate={ctrlImgBase}
                style={{
                    position: "absolute",
                    width: "inherit",
                }}>
                {children}
            </motion.div>
            <Box component={motion.div}
                sx={{
                    ...sxStyleWrap,
                    position: "relative",
                    backgroundImage: isBgImgGradient ? gradient : loadedBg ? `url(${staticPathBgImg})` : "unset",
                    WebkitMaskImage: loadedMask ? `url(${srcMaskImg})` : "unset", // gradient,
                    maskImage: loadedMask ? `url(${srcMaskImg})` : "unset", // gradient,
                    maskPosition: "var(--mid-maskPosition)", // "left",
                    WebkitMaskPosition: "var(--mid-maskPosition)", // "left",
                    display: "inline-block",
                    zIndex: '1',
                    "&::before": {
                        content: "''",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        height: "100%",
                        width: "100%",
                        transform: "var(--bef-x)",
                        WebkitMaskSize: "var(--bef-maskSize)",
                        maskSize: "var(--bef-maskSize)",
                        maskPosition: "var(--bef-maskPosition)", // "left",
                        WebkitMaskPosition: "var(--bef-maskPosition)", // "left",
                        WebkitMaskImage: loadedMaskBef ? `url(${srcMaskImgBef})` : "unset", // gradient,
                        maskImage: loadedMaskBef ? `url(${srcMaskImgBef})` : "unset", // gradient,
                        backgroundImage: isBgImgBefGradient ? gradientBef : loadedBgBef ? `url(${staticPathBgImgBef})` : "unset",
                        zIndex: 1,
                        mixBlendMode: 'luminosity',
                    },
                    "&::after": {
                        content: "''",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        height: "100%",
                        width: "100%",
                        transform: "var(--aft-x)",
                        WebkitMaskSize: "var(--aft-maskSize)",
                        maskSize: "var(--aft-maskSize)",
                        maskPosition: "var(--aft-maskPosition)", // "left",
                        WebkitMaskPosition: "var(--aft-maskPosition)", // "left",
                        WebkitMaskImage: loadedMaskAft ? `url(${srcMaskImgAft})` : "unset", // gradient,
                        maskImage: loadedMaskAft ? `url(${srcMaskImgAft})` : "unset", // gradient,
                        backgroundImage: isBgImgAftGradient ? gradientAft : loadedBgAft ? `url(${staticPathBgImgAft})` : "unset",
                        zIndex: 1,
                        mixBlendMode: "hard-light",
                    }
                }}
                animate={ctrlWrap}
                variants={variants}
                initial="befHidden"
                custom={baseMaskSizePerc}
            >
                {children}
            </Box>
            <IconButton aria-label="replay" size="large"
                onClick={() => {
                    if (window) {
                        setReplay(!replay);
                        window.setTimeout(() => setReplay(true), 600);
                    }
                }}
                style={{ zIndex: 2 }}
                className="renew"
            >
                <AutorenewIcon />
            </IconButton>
        </Box>
    )
}

export default MaskingChildren